import Link from 'next/link'
import Image from 'next/image';
import errorImage from '@/assets/notfound_error.png';
import RefreshButton from './RefreshButton';

// Componente para mostrar mensajes de error específicos
const ErrorMessage = ({ statusCode, errorMessage }: { statusCode: number, errorMessage?: string }) => {
  let message = 'Disculpa, ocurrió un error cargando esta página.';
  const timestamp = new Date().toLocaleString();

  if (statusCode === 404) {
    message = 'Lo sentimos, la página que buscas no existe.';
  } else if (statusCode === 500) {
    message = 'Tuvimos un error interno.';
  }
  return (
    <div className="text-center p-4">
      <h1 className="text-2xl font-semibold mb-4">{message}</h1>
      <small>{errorMessage}</small>
      <p> {timestamp} </p>
    </div>
  );
};

const ErrorBoundary = ({ statusCode, errorMessage }: { statusCode: number, errorMessage?: string }) => {


    return <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <Image src={errorImage} alt="Error" width={200} height={200} />
        <ErrorMessage statusCode={statusCode || 500} errorMessage={errorMessage} />
        <div className="mt-4 w-60">
            <Link href={`/`}>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700'
                >Volver al inicio
              </button>
            </Link>
            <RefreshButton />
        </div>
      </div>
}

export default ErrorBoundary

'use client'
import ErrorBoundary from '@/components/ErrorBoundary/StatusError'
import { errorHandler } from '@/config/stackdriver'
import { useEffect } from 'react'
 
export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {

  useEffect(() => {
    console.error(error)
    errorHandler?.report(error)
  }, [])
 
  return <ErrorBoundary statusCode={500} errorMessage={error.message} />
}